import { graphql } from '~graphqlTypeScript';

export const ACCOUNT_METRIC_TIME_SERIES = graphql(`
  query accountMetricTimeSeries (
    $socialNetworkUserId: String
    $brandId: String
    $postulationId: String
    $since: Date!
    $until: Date!
    $metrics: [String!]!
  ) {
    accountMetric {
      timeSeries (
        socialNetworkUserId: $socialNetworkUserId
        brandId: $brandId
        postulationId: $postulationId
        since: $since
        until: $until
        metrics: $metrics
      ) {
        ... AccountMetricTimeSeriesFragment
        accountMetric {
          ... AccountMetricFragment
        }
      }
    }
  }
`);

export const ACCOUNT_METRIC_TOTAL_VALUE = graphql(`
  query accountMetricTotalValue (
    $socialNetworkUserId: String
    $brandId: String
    $postulationId: String
    $since: Date
    $until: Date
    $metrics: [String!]!
  ) {
    accountMetric {
      totalValues (
        socialNetworkUserId: $socialNetworkUserId
        brandId: $brandId
        postulationId: $postulationId
        since: $since
        until: $until
        metrics: $metrics
      ) {
        ... AccountMetricTotalValueFragment
        accountMetric {
          ... AccountMetricFragment
        }
      }
    }
  }
`);
