import { QueryHookOptions, useQuery } from '@apollo/client';
import { CAMPAIGN_DETAIL } from '~graphql/campaign/query';
import { CampaignDetailsQuery, CampaignDetailsQueryVariables } from '~graphqlTypeScript/graphql';

type Props = {
  campaignId?: string
  queryOptions?: QueryHookOptions<CampaignDetailsQuery, CampaignDetailsQueryVariables>
};

const useCampaignDetail = (props: Props) => {
  const { campaignId, queryOptions } = props;
  const { data, loading } = useQuery(CAMPAIGN_DETAIL, {
    variables: {
      campaignId: campaignId || '',
    },
    skip: !campaignId,
    ...queryOptions,
  });
  return {
    campaignFragment: data?.campaignResource.get,
    loading,
  };
};

export default useCampaignDetail;
