import { useQuery } from '@apollo/client';
import { graphqlDate } from '~formattingValues';
import { ACCOUNT_METRIC_TIME_SERIES } from '~graphql/accountMetric/query';
import { getFragmentData } from '~graphqlTypeScript';
import { AccountMetricFragmentFragmentDoc, AccountMetricQueryTimeSeriesArgs, AccountMetricTimeSeriesFragmentFragmentDoc } from '~graphqlTypeScript/graphql';

type Props = {
  variables: AccountMetricQueryTimeSeriesArgs
  skip?: boolean
};
const useTimeSeriesMetric = (props: Props) => {
  const { variables, skip } = props;
  const { since, until } = variables;
  const { data, loading } = useQuery(ACCOUNT_METRIC_TIME_SERIES, {
    variables: {
      ...variables,
      since: since && graphqlDate(since),
      until: until && graphqlDate(until),
    },
    skip,
  });
  const insights = data?.accountMetric.timeSeries.map((insight) => (
    {
      ...getFragmentData(AccountMetricTimeSeriesFragmentFragmentDoc, insight),
      accountMetric: getFragmentData(AccountMetricFragmentFragmentDoc, insight.accountMetric),
    }
  ));
  return { insights, loading };
};

export default useTimeSeriesMetric;
