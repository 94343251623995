import { useQuery } from '@apollo/client';
import { POSTULATION_DETAIL } from '~graphql/postulation/query';

const usePostulationDetail = (postulationId?: string) => {
  const { data, loading } = useQuery(
    POSTULATION_DETAIL,
    {
      variables: {
        postulationId: postulationId || '',
      },
      skip: !postulationId,
    },
  );
  const postulation = data?.postulationResource.postulation;
  return { postulation, loading };
};

export default usePostulationDetail;
