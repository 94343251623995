import { useApolloClient, useMutation } from '@apollo/client';
import moment from 'moment';
import { useCallback } from 'react';
import { logIn } from '~apollo';
import { OAUTH2_LOGIN } from '~graphql/user/mutation';
import { MY_USER } from '~graphql/user/query';
import { Oauth2LoginMutation } from '~graphqlTypeScript/graphql';

type AuthenticationType = {
  accessToken: string
  permissions: string
  expiresIn: number
  issuedAt?: number | undefined
};

const useOAuthLogin = (
  provider: string,
  onCompleted: (data: Oauth2LoginMutation) => void = () => {},
) => {
  const client = useApolloClient();
  const [oauth, { loading }] = useMutation(OAUTH2_LOGIN, {
    onCompleted: (data) => {
      if (data.user.oauth2Login) {
        logIn(data.user.oauth2Login, client);
      }
      onCompleted(data);
    },
    refetchQueries: [MY_USER],
    awaitRefetchQueries: true,
  });
  const doLogin = useCallback((authentication: AuthenticationType) => {
    oauth({
      variables: {
        connectAccountInput: {
          accessToken: authentication.accessToken,
          permissions: authentication.permissions.split(' '),
          applicationId: '',
          expiresIn: authentication.expiresIn,
          issuedAt: authentication.issuedAt ?? moment().unix(),
          provider,
        },
      },
    });
  }, [oauth]);
  return { doLogin, loading };
};

export default useOAuthLogin;
