import { ArrowRightAlt } from '@mui/icons-material';
import { Box, Link, Paper, Typography } from '@mui/material';

type Props = {
  title: string
  children: React.ReactNode
  mt?: number
  linkLabel?: string
  linkRef?: string
};

const MontuPaperWithTitle = (props: Props) => {
  const {
    title,
    children,
    mt,
    linkLabel,
    linkRef,
  } = props;
  return (
    <Box mt={mt}>
      <Box display="flex" alignItems="center">
        <Typography variant="h3" color="text.secondary">
          {title}
        </Typography>
        {linkLabel ? (
          <>
            <Link href={linkRef} ml="auto" mr={1}>
              {linkLabel}
            </Link>
            <ArrowRightAlt />
          </>
        ) : <></>}
      </Box>
      <Paper sx={{ height: '100%', mt: 2 }}>
        {children}
      </Paper>

    </Box>
  );
};

export default MontuPaperWithTitle;
