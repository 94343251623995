import { Instagram } from '@mui/icons-material';
import { ButtonProps } from '@mui/material';
import { LoginButton } from '~components';

const InstagramButton = (props: ButtonProps) => (
  <LoginButton
    startIcon={<Instagram />}
    component="div"
    backgroundColor="transparent"
    textColor="#fff"
    sx={{
      background: 'linear-gradient(to right, #FEDA75, #FA7E1E, #D62976, #962FBF, #4F5BD5)',
    }}
    {...props}
  />
);
export default InstagramButton;
