import { useCallback, useState } from 'react';

const useCreateRandomKeys = (initialKeys: number = 0) => {
  const generateRandomKey = useCallback(() => (Math.random() + 1).toString(36).substring(2), []);
  const [keys, setKeys] = useState<string[]>(
    [...Array(initialKeys)].map(() => generateRandomKey()),
  );
  const addKey = useCallback(() => {
    setKeys((prev) => [...prev, generateRandomKey()]);
  }, []);
  const removeKey = useCallback((index: number) => {
    setKeys((prev) => {
      const newKeys = prev.splice(index, 1);
      return [...newKeys];
    });
  }, []);
  return { keys, addKey, removeKey, generateRandomKey };
};

export default useCreateRandomKeys;
