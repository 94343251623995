import { Payment } from '@mercadopago/sdk-react';
import { BoxFullUnderHeader } from '~components';

const PruebaPago = () => (
  <BoxFullUnderHeader bgcolor="white">
    <Payment
      initialization={{
        amount: 990,
        items: {
          totalItemsAmount: 990,
          itemsList: [{
            units: 1,
            value: 990,
            name: 'Montu prueba',
          }],
        },
      }}
      customization={{
        paymentMethods: {
          creditCard: 'all',
          maxInstallments: 1,
        },
      }}
      onSubmit={async (...a) => {
        console.log(...a);
      }}
      onError={console.log}
    />
  </BoxFullUnderHeader>
);

export default PruebaPago;
