import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useIsSidebarOpen } from '~hooks';
import Sidebar, { Props as SidebarProps } from '~layout/Sidebar';
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from '~layout/constants';

type Props = {
  sidebarProps: SidebarProps
};

const OutletWithSidebar = ({ sidebarProps }: Props) => {
  const { drawerBig } = useIsSidebarOpen();
  return (
    <Box
      pb={{ md: 1, xs: 0 }}
      height={{ md: 'calc(100% - 48px)', xs: 'calc(100% - 48px)' }}
    >
      <Sidebar {...sidebarProps} />
      <Box
        ml={{ md: drawerBig ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH }}
        py={{ xs: 1, md: 4 }}
        px={{ xs: 0, md: 4 }}
        borderRadius={{ md: 2, xs: 0 }}
        sx={(theme) => ({
          transition: theme.transitions.create('margin-left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          height: 'calc(100%)',
          overflowY: 'auto',
          bgcolor: theme.palette.background.default,
        })}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default OutletWithSidebar;
