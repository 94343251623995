import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyWithRetry } from '~utils/lazyRetry';
import { routes as brandRoutes } from './brand/brandId/routes';
import LoginCallback from './login-callback/page';
import { routes as mainRoutes } from './main/routes';
import { routes as profileRoutes } from './profile/routes';
import PruebaPago from './prueba-pago/page';

const LayoutBrand = lazyWithRetry(() => import('./brand/brandId/layout'));
const MainLayout = lazyWithRetry(() => import('./main/layout'));
const Onboarding = lazyWithRetry(() => import('./onboarding/page'));
const ShopifyInstall = lazyWithRetry(() => import('./ecommerce/shopify-install/page'));
const LayoutProfile = lazyWithRetry(() => import('./profile/layout'));

export const routes: RouteObject[] = [
  {
    path: '',
    element: <Suspense><MainLayout /></Suspense>,
    children: mainRoutes,
  },
  {
    path: 'login-callback',
    element: <LoginCallback />,
  },
  {
    path: 'ecommerce/shopify-install',
    element: <Suspense><ShopifyInstall /></Suspense>,
  },
  {
    path: 'onboarding',
    element: <Suspense><Onboarding /></Suspense>,
  },
  {
    path: 'brand/:brandId',
    element: <Suspense><LayoutBrand /></Suspense>,
    children: brandRoutes,
  },
  {
    path: 'profile',
    element: <Suspense><LayoutProfile /></Suspense>,
    children: profileRoutes,
  },
  {
    path: 'prueba-pago',
    element: <PruebaPago />,
  },
];
