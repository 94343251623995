import { graphql } from '~graphqlTypeScript';

export const LIST_PLANS = graphql(`
  query listPlans (
    $id_In: [ID]
    $id: ID
    $available: Boolean
    $visible: Boolean
    $orderBy: String
  ) {
    subscription {
      listPlans (
        id_In: $id_In
        id: $id
        available: $available
        visible: $visible
        orderBy: $orderBy
      ) {
        ... PlanFragment
        planquotaSet {
          ... PlanQuotaFragment
          quota {
            ... QuotaFragment
          }
        }
        planpricingSet {
          ... PlanPricingFragment
          priceSet {...PriceFragment}
        }
      }
    }
  }
`);

export const SUBSCRIPTION_ABILITIES = graphql(`
  query subscriptionAbilities (
    $workspaceId: String
    $brandId: String
  ) {
    subscription {
      subscriptionAbilities (
        workspaceId: $workspaceId
        brandId: $brandId
      ) {
        ... QuotaDictFragment
      }
    }
  }
`);
