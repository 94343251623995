import { ObservableQuery, OperationVariables } from '@apollo/client';
import React, { useCallback } from 'react';
import { PageInfoFragment } from '~graphql/generic/fragments';
import { FragmentType, getFragmentData } from '~graphqlTypeScript';
import { InputMaybe, PageInfoFragmentFragmentDoc, Scalars } from '~graphqlTypeScript/graphql';

type Props<TData, TVariables extends OperationVariables> = {
  pageInfo?: FragmentType<typeof PageInfoFragment>
  fetchMore?: ObservableQuery<TData, TVariables>['fetchMore']
};

type ConnectionVariables = {
  after?: InputMaybe<Scalars['String']['input']>;
};

const useFetchMoreScroller = <TData, TVariables extends ConnectionVariables>({
  pageInfo, fetchMore,
}: Props<TData, TVariables>) => {
  const pageInfoFields = getFragmentData(
    PageInfoFragmentFragmentDoc,
    pageInfo,
  );
  const onScroll: React.UIEventHandler<HTMLDivElement> = useCallback((event) => {
    if (!pageInfoFields?.hasNextPage) {
      return;
    }
    const { currentTarget } = event;
    const { clientHeight } = currentTarget;
    const offset = currentTarget.scrollHeight - currentTarget.scrollTop;
    if (clientHeight - offset === 0) {
      if (fetchMore) {
        fetchMore({
          variables: {
            after: pageInfoFields.endCursor,
          },
        });
      }
    }
  }, [pageInfoFields]);
  return { onScroll };
};

export default useFetchMoreScroller;
