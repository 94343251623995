import { useQuery } from '@apollo/client';
import { SUBSCRIPTION_ABILITIES } from '~graphql/subscription/query';
import { getFragmentData } from '~graphqlTypeScript';
import { QuotaDictFragmentFragmentDoc, SubscriptionAbilities } from '~graphqlTypeScript/graphql';

type Props = {
  workspaceId?: string,
  brandId?: string
  ability?: SubscriptionAbilities
};

const useHasSubscriptionAbility = ({
  workspaceId,
  brandId,
  ability,
}: Props) => {
  const { data, ...rest } = useQuery(SUBSCRIPTION_ABILITIES, {
    variables: {
      workspaceId,
      brandId,
    },
    skip: !workspaceId && !brandId,
  });
  const abilities = getFragmentData(
    QuotaDictFragmentFragmentDoc,
    data?.subscription.subscriptionAbilities || [],
  );
  const findAbility = (ab?: SubscriptionAbilities) => {
    if (!ab) return undefined;
    const finder = abilities.find((abilityF) => abilityF.ability === ab);
    return finder;
  };
  const abilityFields = findAbility(ability);
  const hasAbility = abilityFields?.can;
  return {
    abilities,
    hasAbility,
    ability: abilityFields,
    findAbility,
    ...rest,
  };
};

export default useHasSubscriptionAbility;
