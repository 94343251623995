import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, Button, CircularProgress } from '@mui/material';
import { SubscriptionAbilities } from '~graphqlTypeScript/graphql';
import { useHasSubscriptionAbility } from '~hooks/subscription';

type Props = {
  children: React.ReactNode
  ability: SubscriptionAbilities
  brandId: string
};

const AbilityView = (props: Props) => {
  const { children, ability, brandId } = props;
  const { hasAbility, loading } = useHasSubscriptionAbility({
    brandId,
    ability,
  });
  if (hasAbility) return children;
  if (loading) return <CircularProgress />;
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 100,
        pt: 40,
      }}
      >
        <Box>
          <Button variant="contained" sx={{ minWidth: 350 }} startIcon={<PriorityHighIcon />}>
            Contrata Montu Starter para ver el análisis de postulación
          </Button>
        </Box>
      </Box>
      <Box className="blurred-value">
        {children}
      </Box>
    </Box>
  );
};

export default AbilityView;
