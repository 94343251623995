import { graphql } from '../../graphqlTypeScript';

export const MY_USER = graphql(`
  query myUser {
    userResource {
      user {
        ... UserFragment
        gender {...GenderFragment}
      }
    }
  }
  `);

export const MY_BRANDS = graphql(`
  query myBrands {
    brandResource {
      userBrands {
        ... UserBrandFragment
        brand {
          ... BrandFragment
          workspacebrand {
            ... WorkspaceBrandFragment
            workspace {
              ... WorkspaceFragment
            }
          }
        }
      }
    }
  }
`);

export const GET_USER = graphql(`
  query getUser ($id: ID!) {
    userResource {
      get (id: $id) {
        ... UserFragment
        gender {...GenderFragment}
        socialnetworkuserSet {
          ... SocialNetworkUserFragment
          socialnetworkusernicheSet {
            ... SocialNetworkUserNicheFragment
            niche {
              ... InfluencerNicheFragment
            }
          }
          collaborations {
            ... SocialNetworkCollaborationFragment
            collaboration {
              ... SocialNetworkUserFragment
            }
          }
        }
        gamificationinterface {
          ... GamificationInterfaceFragment
          level {
            ... GamificationInterfaceLevelFragment
          }
        }
        usernicheSet {
          ... UserNicheFragment
            niche {
              ... InfluencerNicheFragment
            }
            platform {
              ... PlatformFragment
            }
        }
        useraddressSet {
          id
          address {
            ... AddressFields
          }
        }
        userportfolioSet {
          ... UserPortfolioFragment
        }
        userperformance {
          ... UserPerformanceFragment
        }
      }
    }
  }
`);

export const SEARCH_USER = graphql(`
  query searchUser (
    $search: String
    $first: Int
    $after: String
  ) {
    userResource {
      list(search: $search first: $first after: $after) {
        pageInfo {...PageInfoFragment}
        totalCount
        totalPages
        edges {
          cursor
          node {
            id
            fullName
          }
        }
      }
    }
  }
`);

export const LIST_USER = graphql(`
  query listUser (
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [ID!]
    $id: ID
    $gender_Id: ID
    $gender_Id_In: [ID!]
    $gender_Code: String
    $gender_Code_In: [String!]
    $userperformance_AvgReview_Gte: Decimal
    $userperformance_AvgReview_Lte: Decimal
    $userperformance_AvgReview_Range: [Decimal!]
    $userperformance_TotalReviews_Gte: BigInt
    $userperformance_TotalReviews_Lte: BigInt
    $userperformance_TotalReviews_Range: [BigInt!]
    $orderBy: String
    $useraddress_Address_Locality_Name: [String!]
    $useraddress_Address_Locality_Id: [ID!]
    $useraddress_Address_Locality_State_Id: [ID!]
    $userniche_Niche_Id: [ID!]
    $instagramFollowers: [[Int!]!]
    $tiktokFollowers: [[Int!]!]
    $ageBetween: [[Int!]!]
    $hasPortfolio: Boolean
    $hasInstagramVerified: Boolean
    $favoriteByBrand: ID
    $search: String

  ) {
    userResource {
      list (
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        gender_Id: $gender_Id
        gender_Id_In: $gender_Id_In
        gender_Code: $gender_Code
        gender_Code_In: $gender_Code_In
        userperformance_AvgReview_Gte: $userperformance_AvgReview_Gte
        userperformance_AvgReview_Lte: $userperformance_AvgReview_Lte
        userperformance_AvgReview_Range: $userperformance_AvgReview_Range
        userperformance_TotalReviews_Gte: $userperformance_TotalReviews_Gte
        userperformance_TotalReviews_Lte: $userperformance_TotalReviews_Lte
        userperformance_TotalReviews_Range: $userperformance_TotalReviews_Range
        orderBy: $orderBy
        useraddress_Address_Locality_Name: $useraddress_Address_Locality_Name
        useraddress_Address_Locality_Id: $useraddress_Address_Locality_Id
        useraddress_Address_Locality_State_Id: $useraddress_Address_Locality_State_Id
        userniche_Niche_Id: $userniche_Niche_Id
        instagramFollowers: $instagramFollowers
        tiktokFollowers: $tiktokFollowers
        ageBetween: $ageBetween
        hasPortfolio: $hasPortfolio
        hasInstagramVerified: $hasInstagramVerified
        favoriteByBrand: $favoriteByBrand
        search: $search
      ) {
        pageInfo {...PageInfoFragment}
        totalCount
        totalPages
        edges {
          cursor
          node {
            ... UserFragment
            gender {...GenderFragment}
            socialnetworkuserSet {
              ... SocialNetworkUserFragment
              socialnetworkusernicheSet {
                ... SocialNetworkUserNicheFragment
                niche {
                  ... InfluencerNicheFragment
                }
              }
            }
            gamificationinterface {
              ... GamificationInterfaceFragment
              level {
                ... GamificationInterfaceLevelFragment
              }
            }
            usernicheSet {
              ... UserNicheFragment
              niche {
                ... InfluencerNicheFragment
              }
            }
            useraddressSet {
              id
              address {
                ... AddressFields
              }
            }
            userportfolioSet {
              ... UserPortfolioFragment
            }
            userperformance {
              ... UserPerformanceFragment
            }
          }
        }
      }
    }
  }
`);

export const USER_IN_FAVORITE = graphql(`
  query userInFavorite ($userId: ID! $brandId: ID!) {
    userResource {
      list (favoriteByBrand: $brandId id: $userId) {
        totalCount
      }
    }
  }
`);
