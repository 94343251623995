import { Box, BoxProps } from '@mui/material';

const BoxFullUnderHeader = (props: BoxProps) => (
  <Box
    {...props}
    height={{ md: 'calc(100% - 48px)', xs: 'calc(100% - 48px)' }}
  />
);

export default BoxFullUnderHeader;
