import { useQuery } from '@apollo/client';
import { WORKSPACE_USERS } from '~graphql/workspace/query';
import { useMyUser } from '~hooks/user';

const useWorkspaceUsers = () => {
  const { userBasicFields } = useMyUser();
  const { data, loading } = useQuery(WORKSPACE_USERS, {
    variables: {
      workspace_Userworkspace_User_Id: userBasicFields?.id,
      orderBy: 'user_FirstName',
    },
    skip: !userBasicFields,
  });
  const workspaceUsers = data?.workspace.userWorkspaces || [];
  return {
    workspaceUsers, loading,
  };
};

export default useWorkspaceUsers;
