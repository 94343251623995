import { Typography, TypographyProps } from '@mui/material';
import { SubscriptionAbilities } from '~graphqlTypeScript/graphql';
import { useHasSubscriptionAbility } from '~hooks/subscription';

type Props = TypographyProps & {
  ability: SubscriptionAbilities
  brandId: string
};

const AbilityTypography = (props: Props) => {
  const { ability, brandId, ...restProps } = props;
  const { hasAbility } = useHasSubscriptionAbility({
    brandId,
    ability,
  });
  return (
    <Typography
      {...restProps}
      className={!hasAbility ? 'blurred-value' : undefined}
    />
  );
};

export default AbilityTypography;
