import { QueryHookOptions, useQuery } from '@apollo/client';
import { POSTULATIONS } from '~graphql/postulation/query';
import { getFragmentData } from '~graphqlTypeScript';
import { PageInfoFragmentFragmentDoc, PostulationsQuery, PostulationsQueryVariables } from '~graphqlTypeScript/graphql';

const usePostulations = (
  options: QueryHookOptions<PostulationsQuery, PostulationsQueryVariables>,
) => {
  const { data, loading, previousData, ...rest } = useQuery(POSTULATIONS, options);
  const usableData = data || previousData;
  const postulations = usableData?.postulationResource.postulations.edges.map(
    (postulation) => postulation.node,
  ) || [];
  const pageInfo = getFragmentData(
    PageInfoFragmentFragmentDoc,
    data?.postulationResource.postulations.pageInfo,
  );
  return {
    postulations,
    loading,
    data,
    pageInfo,
    previousData,
    usableData,
    ...rest,
  };
};

export default usePostulations;
