import { graphql } from '~graphqlTypeScript';

export const ChatMessageFragment = graphql(`
  fragment ChatMessageFragment on ChatMessageType {
    id
    createdAt
    message
    isEdited
    unreadByBrand
    unreadByUser
    sentByBrand
    upload {
      ... FileFields
    }
    messageType {
      enum
      display
    }
  }
  `);

export const ChatFragment = graphql(`
  fragment ChatFragment on ChatType {
    id
    lastMessageSent
    unreadByBrand
    unreadByUser
  }
`);

export const ChatFragmentForMutation = graphql(`
  fragment ChatFragmentForMutation on ChatType {
    id
    lastMessageSent
    unreadByBrand
    unreadByUser
    user {
      ... UserFragment
    }
    brand {
      ... BrandFragment
    }
  }
`);
