import { graphql } from '~graphqlTypeScript';

export const POSTULATIONS = graphql(`
  query postulations(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [ID]
    $id: ID
    $campaign_Brand_Id_In: [ID]
    $campaign_Brand_Id: ID
    $campaign_Id_In: [ID]
    $campaign_Id: ID
    $user_Id_In: [ID]
    $user_Id: ID
    $orderBy: String
    $searchCampaign: String
    $searchUser: String
    $status: MontuPostulationStatusChoices
    $shortlisted: Boolean
  ) {
    postulationResource {
      postulations(
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        campaign_Brand_Id_In: $campaign_Brand_Id_In
        campaign_Brand_Id: $campaign_Brand_Id
        campaign_Id_In: $campaign_Id_In
        campaign_Id: $campaign_Id
        user_Id_In: $user_Id_In
        user_Id: $user_Id
        orderBy: $orderBy
        searchCampaign: $searchCampaign
        searchUser: $searchUser
        status: $status
        shortlisted: $shortlisted
      ) { 
        pageInfo {
          ... PageInfoFragment
        }
        totalCount
        totalPages
        edges {
          cursor
          node {
            ... PostulationFragment
            user {
              ... UserFragment
              gamificationinterface {
                ... GamificationInterfaceFragment
                level {
                  ... GamificationInterfaceLevelFragment
                }
              }
              socialnetworkuserSet {
                ... SocialNetworkUserFragment
                socialnetworkusernicheSet {
                  ... SocialNetworkUserNicheFragment
                  niche {
                    ... InfluencerNicheFragment
                  }
                }
              }
              usernicheSet {
                ... UserNicheFragment
                niche {
                  ... InfluencerNicheFragment
                }
              }
              useraddressSet {
                id
                address {
                  ... AddressFields
                }
              }
              userportfolioSet {
                ... UserPortfolioFragment
              }
              userperformance {
                ... UserPerformanceFragment
              }
            }
            campaign {
              id
              brand {
                ... BrandFragment
              }
            }
            postulationanswerSet {
              ... PostulationAnswerFragment
              question {
                ... CampaignQuestionFragment
              }
            }
          }
        }
      }
    }
  }
`);

export const POSTULATION_DETAIL = graphql(`
  query postulationDetail(
    $postulationId: ID!
  ) {
    postulationResource {
      postulation(
        id: $postulationId
      ) {
        ... PostulationFragment
        user {
          ... UserFragment
          socialnetworkuserSet {
            ... SocialNetworkUserFragment
            socialnetworkusernicheSet {
              ... SocialNetworkUserNicheFragment
              niche {
                ... InfluencerNicheFragment
              }
            }
            collaborations {
              ... SocialNetworkCollaborationFragment
              collaboration {
                ... SocialNetworkUserFragment
              }
            }
          }
          usernicheSet {
            ... UserNicheFragment
            niche {
              ... InfluencerNicheFragment
            }
            platform {
              ... PlatformFragment
            }
          }
          gender {
            ... GenderFragment
          }
          useraddressSet {
            id
            address {
              ... AddressFields
            }
          }
          userportfolioSet {
            ... UserPortfolioFragment
          }
        }
        campaign {
          ... CampaignFragment
          brand {
            ... BrandFragment
          }
        }
        workorderSet {
          ... WorkOrderFragment
        }
        postulationanswerSet {
          ... PostulationAnswerFragment
          question {
            ... CampaignQuestionFragment
          }
        }
        address {
          id
          address {...AddressFields}
        }
      }
    }
  }
`);
