import { useMutation } from '@apollo/client';
import { CREATE_CAMPAIGN } from '~graphql/campaign/mutation';
import { CampaignInputType, CreateCampaignMutation } from '~graphqlTypeScript/graphql';

const useCreateCampaign = (
  newCampaign: CampaignInputType,
  onCompleted: (data: CreateCampaignMutation) => void = () => {},
) => {
  const [createCampaign, { loading }] = useMutation(CREATE_CAMPAIGN, {
    variables: {
      campaign: newCampaign,
    },
    onCompleted,
  });
  return { createCampaign, loading };
};

export default useCreateCampaign;
