import { useQuery } from '@apollo/client';
import { MY_USER } from '~graphql/user/query';
import { getFragmentData } from '~graphqlTypeScript';
import { MyUserQuery, UserFragmentFragmentDoc } from '~graphqlTypeScript/graphql';

const useMyUser = (onCompleted: (data: MyUserQuery) => void = () => {}) => {
  const { data, ...rest } = useQuery(
    MY_USER,
    {
      onCompleted,
    },
  );
  const user = data?.userResource.user;
  const userBasicFields = getFragmentData(
    UserFragmentFragmentDoc,
    user,
  );
  return { user, userBasicFields, ...rest };
};

export default useMyUser;
