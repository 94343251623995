import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback } from 'react';

type Props = {
  textFieldProps?: Omit<TextFieldProps, 'onChange' | 'value'>
  onChange: (s: string) => void
};

const SearchField = (props: Props) => {
  const { textFieldProps, onChange } = props;
  const debounced = debounce(onChange, 500);
  const onChangeCallback: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    debounced(event.target.value);
  }, []);
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      hiddenLabel
      onChange={onChangeCallback}
      size="medium"
      {...textFieldProps}
    />
  );
};

export default SearchField;
