import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Box, Typography } from '@mui/material';

type Props = {
  avg: number | null | undefined
  top: number
  bottom: number

};
const DIMENSIONS = {
  width: '25vw',
  height: '25vw',
};
const RatingStar = (props: Props) => {
  const { avg, top, bottom } = props;
  const width = avg ? (((avg - bottom) / (top - bottom)) * 0.72) + 0.14 : 0;
  return (
    <Box sx={{
      display: 'inline-block',
      position: 'relative',
      overflow: 'hidden',
    }}
    >
      <Box
        sx={{
          width: `${100 - (width * 100)}%`,
          overflow: 'hidden',
          direction: 'rtl',
          float: 'right',
        }}
      >
        <StarOutlineRoundedIcon
          sx={{
            ...DIMENSIONS,
            opacity: 0.4,
          }}
          color="primary"
        />
      </Box>
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: `${width * 100}%`,
        overflow: 'hidden',
      }}
      >
        <StarRoundedIcon
          sx={{
            ...DIMENSIONS,
            opacity: 0.4,
          }}
          color="primary"
        />
      </Box>
      <Typography
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        variant="h1"
      >
        {avg || '-'}
      </Typography>
    </Box>
  );
};

export default RatingStar;
