import { graphql } from '~graphqlTypeScript';

export const WORKSPACE_USERS = graphql(`
  query workspaceUsers(
    $workspaceId: ID
    $orderBy: String
    $user_Id: ID
    $workspace_Userworkspace_User_Id: ID
  ) {
    workspace {
      userWorkspaces(
        workspace_Id: $workspaceId
        orderBy: $orderBy
        user_Id: $user_Id
        workspace_Userworkspace_User_Id: $workspace_Userworkspace_User_Id
      ){
        ...UserWorkspaceFragment
        user {... UserFragment}
        workspace {
          ...WorkspaceFragment
          workspaceplan {
            ...WorkspacePlanFragment
            plan {...PlanFragment}
          }
        }
      }
    }
  }
`);

export const MY_WORKSPACE = graphql(`
  query myWorkspace {
    workspace {
      myWorkspace {
        ...UserWorkspaceFragment
        workspace {
          ...WorkspaceFragment
          workspaceplan {
            ...WorkspacePlanFragment
            plan {...PlanFragment}
          }
        }
      }
    }
  }  
`);
