import { useQuery } from '@apollo/client';
import { CAMPAIGN_QUERY_VARIABLES } from '~graphql/campaign/query';
import { getFragmentData } from '~graphqlTypeScript';
import { CampaignProductRelationFragmentFragmentDoc, CountryFragmentFragmentDoc, FollowersCharacterizationFragmentFragmentDoc, GenderFragmentFragmentDoc, InfluencerNicheFragmentFragmentDoc, PlatformFragmentFragmentDoc, PostTypeFragmentFragmentDoc, PreUploadRequirementFragmentFragmentDoc } from '~graphqlTypeScript/graphql';

const useCreateCampaignVariables = () => {
  const { data, loading } = useQuery(CAMPAIGN_QUERY_VARIABLES, {
    variables: {
      countryCodeIn: ['CL'],
    },
  });
  const campaignProductRelationsFields = getFragmentData(
    CampaignProductRelationFragmentFragmentDoc,
    data?.generic.campaignProductRelations,
  ) || [];
  const preUploadRequirementsFields = getFragmentData(
    PreUploadRequirementFragmentFragmentDoc,
    data?.generic.preUploadRequirements,
  ) || [];
  const postTypeFields = data?.generic.postTypes.map((postType) => {
    const post = getFragmentData(PostTypeFragmentFragmentDoc, postType);
    const platform = getFragmentData(
      PlatformFragmentFragmentDoc,
      post?.platform,
    );
    return {
      ...post,
      platform,
    };
  }) || [];
  const countries = getFragmentData(
    CountryFragmentFragmentDoc,
    data?.countryCity.countries,
  ) || [];
  const genders = getFragmentData(
    GenderFragmentFragmentDoc,
    data?.generic.genders,
  ) || [];
  const influencerNiches = data?.generic.influencerNiches.map(
    (g) => getFragmentData(InfluencerNicheFragmentFragmentDoc, g),
  ).sort((a, b) => {
    const nameA = a?.name.display?.toUpperCase() || '';
    const nameB = b?.name.display?.toUpperCase() || '';
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }) || [];
  const followersCharacterization = data?.generic.followersCharacterization.map(
    (g) => getFragmentData(FollowersCharacterizationFragmentFragmentDoc, g),
  ).sort((a, b) => (a?.minFollowers || 0) - (b?.minFollowers || 0)) || [];
  return {
    campaignProductRelationsFields,
    preUploadRequirementsFields,
    postTypeFields,
    countries,
    genders,
    influencerNiches,
    followersCharacterization,
    loading,
  };
};

export default useCreateCampaignVariables;
