import { useQuery } from '@apollo/client';
import { POST_TYPES } from '~graphql/post/query';
import { getFragmentData } from '~graphqlTypeScript';
import { PlatformFragmentFragmentDoc, PostTypeFragmentFragmentDoc } from '~graphqlTypeScript/graphql';

const usePostTypes = () => {
  const { data, loading } = useQuery(POST_TYPES);
  const postTypeFields = data?.generic.postTypes.map((postType) => {
    const post = getFragmentData(PostTypeFragmentFragmentDoc, postType);
    const platform = getFragmentData(
      PlatformFragmentFragmentDoc,
      post?.platform,
    );
    return {
      ...post,
      platform,
    };
  }) || [];
  return {
    postTypeData: data?.generic.postTypes || [],
    loading,
    postTypeFields,
  };
};

export default usePostTypes;
