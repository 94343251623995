import moment from 'moment';

export const withDayString = (date: string) => {
  const s = moment(date).format('dddd DD [de] MMMM');
  return s[0].toUpperCase() + s.slice(1);
};

export const graphqlDate = (date: moment.Moment) => (
  date.format('YYYY-MM-DD')
);
export const numberDateMoment = (date: moment.Moment) => date.format('DD/MM/YYYY');

export const numberDate = (date: string) => {
  const s = moment(date);
  return numberDateMoment(s);
};
