import { graphql } from '~graphqlTypeScript';

export const CREATE_MESSAGE = graphql(`
  mutation createMessage($chatMessage: ChatMessageInputType!) {
    chat {
      createChatMessage(chatMessage: $chatMessage){
        chatMessage {
          ... ChatMessageFragment
          chat {
            ... ChatFragment
            lastMessage {
              ... ChatMessageFragment
            }
          }
        }
      }
    }
  }
`);

export const READ_ALL_CHAT_MESSAGES = graphql(`
  mutation readAllChatMessages($chatId: String!) {
    chat {
      readAllChatMessages (chatId: $chatId) {
        chatMessages {
          ... ChatMessageFragment
          chat {
            ... ChatFragment
          }
        }
      }
    }
  }
`);

export const CREATE_NEW_CHAT = graphql(`
  mutation createChat($chat: ChatInputType!) {
    chat {
      createChat(chat: $chat) {
        chat {
          ... ChatFragment
          brand {
            ... BrandFragment
          }
          user {
            ... UserFragment
          }
        }
      }
    }
  }
`);
