import { TextField, TextFieldProps } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { formatMoney } from '~formattingValues';
import { MoneyInput } from '~graphqlTypeScript/graphql';

export type Props = {
  value?: MoneyInput
  onChange?: (event: {
    target: { value: MoneyInput }
  }) => void
} & Omit<Omit<TextFieldProps, 'value'>, 'onChange'>;

const MoneyFieldInput = ({
  value: initialValue = {
    amount: '',
    currency: 'CLP',
  },
  onChange: initialOnChange = () => { },
  ...props
}: Props) => {
  const formattedMoney = useMemo(
    () => formatMoney(initialValue),
    [initialValue, initialValue.amount, initialValue.currency],
  );
  const onChange = useCallback((
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    const newValue = value.replace(/\D/g, '');
    const newEvent = {
      target: {
        value: {
          amount: newValue,
          currency: initialValue.currency,
        },
      },
    };
    initialOnChange(newEvent);
  }, [initialValue]);
  return (
    <TextField
      {...props}
      value={formattedMoney}
      onChange={onChange}
    />
  );
};

export default MoneyFieldInput;
