import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { COUNTRIES } from '~graphql/country/query';
import { getFragmentData } from '~graphqlTypeScript';
import { CountryFragmentFragmentDoc } from '~graphqlTypeScript/graphql';

const useGetCountries = () => {
  const { data, ...rest } = useQuery(COUNTRIES);
  const countries = data?.countryCity.countries.map(
    (c) => getFragmentData(CountryFragmentFragmentDoc, c),
  ) || [];
  const getCountryByCode = useCallback((code: string) => countries.find(
    (c) => c.code === code,
  ), [countries]);
  return { data, getCountryByCode, ...rest };
};

export default useGetCountries;
