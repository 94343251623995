import { graphql } from '~graphqlTypeScript';

export const CHATS = graphql(`
  query chats(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [ID]
    $id: ID
    $user_Id_In: [ID]
    $user_Id: ID
    $brand_Id_In: [ID]
    $brand_Id: ID
    $orderBy: String
    $search: String
  ) {
    chat {
      list(
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        user_Id_In: $user_Id_In
        user_Id: $user_Id
        brand_Id_In: $brand_Id_In
        brand_Id: $brand_Id
        orderBy: $orderBy
        search: $search
      ) {
        pageInfo {
          ... PageInfoFragment
        }
        edges {
          cursor
          node {
            ... ChatFragment
            user {
              ... UserFragment
            }
            brand {
              ... BrandFragment
            }
            lastMessage {
              ... ChatMessageFragment
            }
          }
        }
      }
    }
  }
`);

export const CHAT_MESSAGES = graphql(`
  query chatMessages(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [ID]
    $id: ID
    $chat_Id_In: [ID]
    $chat_Id: ID
    $unreadByBrand: Boolean
    $unreadByUser: Boolean
    $orderBy: String
  ) {
    chat {
      listMessages (
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        chat_Id_In: $chat_Id_In
        chat_Id: $chat_Id
        unreadByBrand: $unreadByBrand
        unreadByUser: $unreadByUser
        orderBy: $orderBy
      ) {
        pageInfo {
          ... PageInfoFragment
        }
        edges {
          cursor
          node {
            ... ChatMessageFragment
            chat {
              ... ChatFragment
              user {
                ... UserFragment
              }
              brand {
                ... BrandFragment
              }
              lastMessage {
                ... ChatMessageFragment
              }
            }
            createdBy {
              ... UserFragment
            }
          }
        }
      }
    }
  }
`);

export const CURRENT_CHAT_WORK_ORDER_POSTULATION = graphql(`
  query currentChatWorkOrderPostulation(
    $chatId: ID!
  ) {
    chat {
      pendingPostulations(chatId: $chatId) {
        ... PostulationFragment
        campaign {
          ... CampaignFragment
          campaignmediaSet {
            ... CampaignMediaFragment
          }
        }
      }
      currentWorkOrder(chatId: $chatId) {
        ... WorkOrderFragment
        campaign {
          ... CampaignFragment
          campaignmediaSet {
            ... CampaignMediaFragment
          }
        }
      }
    }  
  }
`);
