import { Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@mui/material';
import React, { useMemo } from 'react';
import BackNextBox, { BackNextBoxProps } from './BackNextBox';

type Props = {
  title: string
  open: boolean
  onClose: () => void
  paragraphs?: string[] | string | React.ReactNode
  actionsProps?: BackNextBoxProps
  dialogProps?: Omit<DialogProps, 'open' | 'onClose'>
  error?: string | null
};

const MontuActionDialog = ({
  title,
  paragraphs,
  actionsProps,
  dialogProps,
  open,
  onClose,
  error = null,
}: Props) => {
  const component = useMemo(() => {
    if (React.isValidElement(paragraphs)) {
      return paragraphs;
    }
    const array = [];
    if (typeof paragraphs === 'string') {
      array.push(paragraphs);
    }
    if (Array.isArray(paragraphs)) {
      array.push(...paragraphs);
    }
    return array.map((content) => (
      <DialogContentText key={content}>{content}</DialogContentText>
    ));
  }, [paragraphs]);
  return (
    <Dialog open={open} onClose={onClose} {...dialogProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {component}
        {error ? (
          <DialogContentText
            variant="body2"
            color="error"
            textAlign="right"
          >
            {error}
          </DialogContentText>
        ) : <></>}

      </DialogContent>
      {actionsProps ? (
        <DialogActions>
          <BackNextBox {...actionsProps} />
        </DialogActions>

      ) : <></>}
    </Dialog>
  );
};

export default MontuActionDialog;
