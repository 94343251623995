import { NetworkStatus, useQuery } from '@apollo/client';
import { WORK_ORDERS } from '~graphql/workOrder/query';
import { getFragmentData } from '~graphqlTypeScript';
import { PageInfoFragmentFragmentDoc, WorkOrderQueryListArgs } from '~graphqlTypeScript/graphql';

type Props = {
  variables: WorkOrderQueryListArgs
};

const useWorkOrders = (props: Props) => {
  const { variables } = props;
  const { data, networkStatus, ...rest } = useQuery(WORK_ORDERS, {
    variables,
  });
  const refetching = networkStatus === NetworkStatus.refetch;
  const listing = data?.workOrder.list;
  const pageInfo = getFragmentData(PageInfoFragmentFragmentDoc, listing?.pageInfo);
  return {
    data,
    workOrders: listing?.edges.map((e) => e.node),
    pageInfo,
    networkStatus,
    refetching,
    ...rest,
  };
};

export default useWorkOrders;
