import { useQuery } from '@apollo/client';
import { WORKSPACE_USERS } from '~graphql/workspace/query';
import { getFragmentData } from '~graphqlTypeScript';
import { WorkspaceFragmentFragmentDoc } from '~graphqlTypeScript/graphql';
import { useMyUser } from '.';

const useMyWorkspaces = (workspaceId?: string) => {
  const { userBasicFields } = useMyUser();
  const { data, loading } = useQuery(WORKSPACE_USERS, {
    variables: {
      user_Id: userBasicFields?.id,
    },
  });
  const workspaces = data?.workspace.userWorkspaces || [];
  const selectedWorkspace = workspaces.find(
    (userWorkspace) => {
      const workspace = getFragmentData(
        WorkspaceFragmentFragmentDoc,
        userWorkspace?.workspace,
      );
      return workspace?.id === workspaceId;
    },
  );
  return { workspaces, loading, selectedWorkspace };
};

export default useMyWorkspaces;
