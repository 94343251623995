import { graphql } from '~graphqlTypeScript';

export const MoneyFields = graphql(`
  fragment MoneyFields on Money {
    amount
    amountStr
    asString
    currency {
      code
      name
    }
    byThousands(
      groupSeparator: true
      currencyDigits: true
      decimalQuantization: true
      locale: "es_ES"
      format: "$#,###K"
    )
    thousandSeparator: formatted(
      groupSeparator: true
      currencyDigits: true
      decimalQuantization: true
      locale: "es_ES"
      format: "$#,###"
    )
  }
`);

export const FileFields = graphql(`
  fragment FileFields on FileFieldType {
    name
    url
  }
`);

export const AddressFields = graphql(`
  fragment AddressFields on AddressType {
    id
    streetNumber
    route
    locality {
      id
      name
      postalCode
      state {
        id
        name
        code
        display
        country {
          id
          name
          code
        }
      }
    }
    raw
    formatted
    latitude
    longitude
  }
`);

export const GroupFragment = graphql(`
  fragment GroupFragment on GroupType {
    id
    name
  }
`);

export const PageInfoFragment = graphql(`
  fragment PageInfoFragment on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`);

export const CountryFragment = graphql(`
  fragment CountryFragment on CountryType {
    id
    name
    code
  }
`);

export const StateFragment = graphql(`
  fragment StateFragment on StateType {
    id
    name
    code
  }
`);

export const GenderFragment = graphql(`
  fragment GenderFragment on GenderType {
    id
    name
    code
  }
`);

export const InfluencerNicheFragment = graphql(`
  fragment InfluencerNicheFragment on InfluencerNicheType {
    id
    name {
      display
      enum
    }
    description
  }
`);

export const FollowersCharacterizationFragment = graphql(`
  fragment FollowersCharacterizationFragment on FollowersCharacterizationType {
    id
    minFollowers
    maxFollowers
    display
  }
`);

export const DateTimeRangeFragment = graphql(`
  fragment DateTimeRangeFragment on DateTimeRangeType {
    start
    end
  }
`);

export const TaxFragment = graphql(`
  fragment TaxFragment on TaxType {
    id
    name {enum display}
    rate
  }  
`);
