import { graphql } from '~graphqlTypeScript';

export const STATES = graphql(`
  query states($countryId: ID $country_Code: String) {
    countryCity {
      states(country_Id: $countryId country_Code: $country_Code) {
        ... StateFragment
      }
    }
  }
`);

export const COUNTRIES = graphql(`
  query countries {
    countryCity {
      countries {
        ... CountryFragment
      }
    }
  }
`);

export const LOCALITIES = graphql(`
  query localities($stateId: ID!) {
    countryCity {
      localities(stateId: $stateId) {
        id
        name
      }
    }
  }  
`);
