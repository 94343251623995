import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { LoadingButtonProps } from '@mui/lab';
import { Box, IconButton, Typography } from '@mui/material';
import { isArray } from 'lodash';
import { useMemo } from 'react';
import { SubscriptionAbilities } from '~graphqlTypeScript/graphql';
import AbilityButton from './AbilityButton';

type TitleButtonProp = {
  id?: string
  label: string | React.ReactNode
  onClick?: () => void
  ButtonProps?: Omit<LoadingButtonProps, 'children'>
  workspaceId?: string
  brandId?: string
  ability?: SubscriptionAbilities
  tooltipOnDisabled?: string
};

type Props = {
  title: string
  button?: TitleButtonProp | TitleButtonProp[]
  showBack?: boolean
  backRef?: string
};

const TitleWithActionButton = ({
  title,
  button,
  showBack = false,
  backRef = '..',
}: Props) => {
  const buttonComponent = useMemo(() => {
    if (!button) return <></>;
    let buttons;
    if (isArray(button)) {
      buttons = button;
    } else {
      buttons = [button];
    }
    return buttons.map((but) => (
      <AbilityButton
        key={but.id || 'no-index'}
        buttonProps={{
          variant: 'contained',
          onClick: but.onClick,
          ...but.ButtonProps,
          sx: {
            ml: 1,
            ...but.ButtonProps?.sx,
          },
        }}
        workspaceId={but.workspaceId}
        brandId={but.brandId}
        ability={but.ability}
        tooltipTitleOnDisabled={but.tooltipOnDisabled}
      >
        {but.label}
      </AbilityButton>
    ));
  }, [button]);
  return (
    <Box
      display="flex"
      alignItems={{
        xs: 'flex-start',
        md: 'center',
      }}
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
    >
      {showBack ? (
        <IconButton href={backRef} sx={{ mr: 4 }}>
          <ArrowBackOutlinedIcon fontSize="large" />
        </IconButton>
      ) : <></>}
      <Typography variant="h2" sx={{ flex: 1 }}>
        {title}
      </Typography>
      {buttonComponent}
    </Box>
  );
};

export default TitleWithActionButton;
