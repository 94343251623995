import { graphql } from '../../graphqlTypeScript';

export const REVOKE_TOKEN = graphql(`
  mutation revokeToken(
    $refreshToken: String!
  ) {
    user {
      revokeToken(
        refreshToken: $refreshToken
      ) {
        revoked
      }
    }
  }
`);

export const OAUTH2_LOGIN = graphql(`
  mutation oauth2Login(
    $connectAccountInput: ConnectAccountInput!
  ) {
    user {
      oauth2Login(
        connectAccountInput: $connectAccountInput
      ) {
        __typename
        payload
        token
        refreshToken
        refreshExpiresIn
      }
    }
  }
`);

export const LOGIN = graphql(`
  mutation loginUser(
    $email: String!
    $password: String!
  ) {
    user {
      tokenAuth(
        email: $email
        password: $password
      ) {
        payload
        refreshExpiresIn
        token
        refreshToken
      }
    }
  }
`);

export const EDIT_USER = graphql(`
  mutation editUser($userInput: EditUserInput) {
    user {
      editUser(userInput: $userInput) {
        user {...UserFragment}
        payload
        refreshExpiresIn
        token
        refreshToken
      }
    }
  }
`);

export const VALIDATE_FAST_ONBOARDING = graphql(`
  mutation validateFastOnboarding($fullName: String! $email: String!) {
    user {
      validateFastOnboarding(fullName: $fullName email: $email) {
        isValid
      }
    }
  }
`);

export const FAST_ONBOARDING = graphql(`
  mutation fastOnboarding($fastOnboarding: FastOnboardingInput!) {
    user {
      fastOnboarding(onboarding: $fastOnboarding) {
       success 
      }
    }
  }
`);

export const FAST_LOGIN = graphql(`
  mutation fastLogin($token: UUID!) {
    user {
      fastLogin(token: $token) {
        payload
        token
        refreshToken
        refreshExpiresIn
      }
    }
  }
`);
