import { graphql } from '~graphqlTypeScript';

export const ECOMMERCE_PRODUCTS = graphql(`
  query ecommerceProducts (
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [ID]
    $id: ID
    $socialNetworkUser_Id_In: [ID]
    $socialNetworkUser_Id: ID
    $socialNetworkUser_Brandsocialnetworkuser_Brand_Id: ID
    $orderBy: String
    $search: String
  ) {
    ecommerce {
      listProducts(
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        socialNetworkUser_Id_In: $socialNetworkUser_Id_In
        socialNetworkUser_Id: $socialNetworkUser_Id
        socialNetworkUser_Brandsocialnetworkuser_Brand_Id: $socialNetworkUser_Brandsocialnetworkuser_Brand_Id
        orderBy: $orderBy
        search: $search
      ) {
        pageInfo { ... PageInfoFragment }
        totalCount
        totalPages
        edges {
          cursor
          node { ... EcommerceProductFragment }
        }
      }
    }
  }
`);

export const ECOMMERCE_DISCOUNT_CODES = graphql(`
  query ecommerceDiscountCodes(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [ID!]
    $id: ID
    $socialNetworkUser_Brandsocialnetworkuser_Brand_Id: ID
    $status: String
    $orderBy: String
    $search: String
  ) {
    ecommerce {
      listDiscountCodes(
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        socialNetworkUser_Brandsocialnetworkuser_Brand_Id: $socialNetworkUser_Brandsocialnetworkuser_Brand_Id
        status: $status
        orderBy: $orderBy
        search: $search
      ) {
        pageInfo {... PageInfoFragment}
        totalCount
        totalPages
        edges {
          cursor
          node {...DiscountCodeFragment}
        }
      }
    }
  }  
`);
