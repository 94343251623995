import { ApolloCache, ApolloClient } from '@apollo/client';
import moment from 'moment';
import { ChatFragment, ChatFragmentForMutation } from '~graphql/chat/fragment';
import { CHATS, CHAT_MESSAGES } from '~graphql/chat/query';
import { getFragmentData } from '~graphqlTypeScript';
import { ChatFragmentFragmentDoc, ChatMessageInputType, ChatMessageTypeEdge, ChatMessagesQuery, CreateMessageMutation } from '~graphqlTypeScript/graphql';

export const createOptimisticResponse = (vars: {
  chatMessage: ChatMessageInputType;
  client: ApolloClient<object>
  chatId: string
}) => {
  const { client, chatMessage, chatId } = vars;
  const chat = client.cache.readFragment({
    id: `ChatType:${chatId}`,
    fragment: ChatFragmentForMutation,
    fragmentName: 'ChatFragmentForMutation',
  });
  const message = {
    __typename: 'ChatMessageType',
    id: chatMessage.message,
    message: chatMessage.message,
    createdAt: moment().toISOString(),
    isEdited: false,
    unreadByBrand: true,
    unreadByUser: true,
    sentByBrand: true,
  };
  return {
    chat: {
      createChatMessage: {
        chatMessage: {
          ...message,
          chat: {
            ...chat,
            lastMessage: message,
          },
        },
      },
    },
  } as unknown as CreateMessageMutation;
};

export const updateChatMessageCache = (vars: {
  message: ChatMessagesQuery['chat']['listMessages']['edges'][number]['node'],
  cache: ApolloCache<any>
  chatId: string,
  first?: number
}) => {
  const { message, cache, chatId, first } = vars;
  cache.updateQuery({
    query: CHAT_MESSAGES,
    variables: {
      chat_Id: chatId,
      orderBy: '-id',
      first,
    },
  }, (prevData) => {
    if (!prevData) return null;
    const edges = [...prevData.chat.listMessages.edges || []];
    const newEdge = {
      cursor: edges[0]?.cursor || '',
      node: { ...message },
      __typename: 'ChatMessageTypeEdge',
    } as ChatMessageTypeEdge;
    return {
      chat: {
        ...prevData.chat,
        listMessages: {
          ...prevData.chat.listMessages,
          edges: [newEdge, ...edges],
        },
      },
    };
  });
};

export const updateChatsFromChatMessage = (vars: {
  message: ChatMessagesQuery['chat']['listMessages']['edges'][number]['node'],
  cache: ApolloCache<any>
  brandId: string
}) => {
  const { message, cache, brandId } = vars;
  const chat = getFragmentData(
    ChatFragment,
    message.chat,
  );
  cache.updateQuery({
    query: CHATS,
    variables: {
      orderBy: '-lastMessageSent',
      brand_Id: brandId,
    },
  }, (prevData) => {
    if (!prevData) return null;
    const newEdges = prevData.chat.list.edges.toSorted((a, b) => {
      const aF = getFragmentData(
        ChatFragmentFragmentDoc,
        a.node,
      );
      const bF = getFragmentData(
        ChatFragmentFragmentDoc,
        b.node,
      );
      return moment(bF.lastMessageSent).unix() - moment(aF.lastMessageSent).unix();
    });
    if (!newEdges.map((e) => getFragmentData(
      ChatFragmentFragmentDoc,
      e.node,
    ).id).includes(chat.id)) {
      newEdges.unshift({
        cursor: '',
        node: message.chat,
      });
    }
    return {
      chat: {
        ...prevData.chat,
        list: {
          ...prevData.chat.list,
          edges: newEdges,
        },
      },
    };
  });
};
