import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '~apollo';

const PrivateRoute = ({ children }: { children?: React.ReactNode | undefined }) => {
  const isLogged = useReactiveVar(isLoggedIn);
  const navigate = useNavigate();
  useEffect(() => {
    if (isLogged === false) {
      const next = window.location.href;
      const params = new URLSearchParams();
      params.set('next', next);
      navigate(`/?${params.toString()}`);
    }
  }, [isLogged]);
  return children;
};

export default PrivateRoute;
