import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { SubscriptionAbilities } from '~graphqlTypeScript/graphql';
import { useHasSubscriptionAbility } from '~hooks/subscription';

type Props = {
  buttonProps?: Omit<LoadingButtonProps, 'children'>,
  workspaceId?: string
  brandId?: string
  ability?: SubscriptionAbilities
  children: React.ReactNode
  tooltipTitleOnDisabled?: string
};

const TitleComponent = ({ title }: { title: string }) => (
  <Box display="flex">
    <ErrorOutlineIcon />
    <Typography variant="body2" color="white" ml={2}>
      {title}
    </Typography>
  </Box>
);

const AbilityButton = (props: Props) => {
  const { workspaceId, brandId, buttonProps, ability, children, tooltipTitleOnDisabled } = props;
  const { loading: loadingButtonProp, ...restButtonProps } = buttonProps || {};
  const { hasAbility, loading } = useHasSubscriptionAbility({
    workspaceId,
    brandId,
    ability,
  });
  const isDisabled = !!ability && !hasAbility;
  const tooltipTitle = isDisabled ? <TitleComponent title={tooltipTitleOnDisabled || ''} /> : '';
  return (
    <Tooltip title={tooltipTitle} arrow>
      <Box component="span" sx={{ display: 'flex' }}>
        <LoadingButton
          {...restButtonProps}
          disabled={isDisabled}
          loading={loading || loadingButtonProp}
        >
          {children}
        </LoadingButton>
      </Box>
    </Tooltip>
  );
};
export default AbilityButton;
