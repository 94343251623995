import { useQuery } from '@apollo/client';
import { CAMPAIGNS_DASHBOARD } from '~graphql/campaign/query';
import { CampaignDashboardQueryVariables } from '~graphqlTypeScript/graphql';

const useCampaignDashboardQuery = (
  variables: CampaignDashboardQueryVariables,
) => {
  const { data, loading, ...rest } = useQuery(
    CAMPAIGNS_DASHBOARD,
    { variables },
  );
  const campaigns = data?.campaignResource.list.edges.map(
    (edge) => edge.node,
  );
  return { campaigns, loading, data, ...rest };
};

export default useCampaignDashboardQuery;
