import { FragmentType, getFragmentData } from '~graphqlTypeScript';
import { MoneyFieldsFragmentDoc } from '~graphqlTypeScript/graphql';

export type MoneyProp = {
  amount: string | number
  currency: string
};

export const formatMoney = (money: MoneyProp) => {
  const options = {
    style: 'currency',
    useGrouping: true,
    currency: money.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };
  if (money.amount === '') return '';
  const numberMoney = Number(money.amount || 0);
  return (
    new Intl.NumberFormat('es-CL', options).format(numberMoney).replaceAll(',', '.')
  );
};

export const sumMoney = (
  money1: MoneyProp,
  money2: MoneyProp,
) => ({
  amount: Number(money1.amount) + Number(money2.amount),
  currency: money1.currency,
});

export const ZERO_MONEY_PROP = {
  amount: 0,
  currency: 'CLP',
};

export const formatMoneyFromFragment = (
  moneyFragment?: FragmentType<typeof MoneyFieldsFragmentDoc> | null,
) => {
  const money = getFragmentData(
    MoneyFieldsFragmentDoc,
    moneyFragment,
  );
  if (!money) return formatMoney(ZERO_MONEY_PROP);
  return formatMoney({
    amount: money.amount,
    currency: money.currency.code,
  });
};
