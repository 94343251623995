import { useApolloClient, useMutation } from '@apollo/client';
import { ChatFragmentForMutation } from '~graphql/chat/fragment';
import { CREATE_MESSAGE } from '~graphql/chat/mutation';
import { ChatMessagesQuery } from '~graphqlTypeScript/graphql';
import { useMyUser } from '~hooks';
import { createOptimisticResponse, updateChatMessageCache } from './util';

type Props = {
  chatId: string
  first: number
};

const useSendChatMessage = ({ chatId, first }: Props) => {
  const client = useApolloClient();
  const { user } = useMyUser();
  const [createMessage, { loading }] = useMutation(CREATE_MESSAGE, {
    optimisticResponse: (vars) => createOptimisticResponse({
      chatMessage: vars.chatMessage,
      client,
      chatId,
    }),
    update: (cache, options) => {
      if (!options.data?.chat.createChatMessage?.chatMessage) return;
      const originalMessage = options.data.chat.createChatMessage.chatMessage;
      const chat = cache.readFragment({
        id: `ChatType:${chatId}`,
        fragment: ChatFragmentForMutation,
        fragmentName: 'ChatFragmentForMutation',
      });
      if (!chat) return;
      const message: ChatMessagesQuery['chat']['listMessages']['edges'][number]['node'] = {
        ...originalMessage,
        chat: {
          ...chat,
        },
        createdBy: user,
      };
      updateChatMessageCache({
        message,
        chatId,
        cache: client.cache,
        first,
      });
    },
  });
  return { createMessage, loading };
};

export default useSendChatMessage;
