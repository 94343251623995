import { graphql } from '~graphqlTypeScript';

export const SUBSCRIBE_TO_NEW_CHAT = graphql(`
  subscription subscribeToNewChat(
    $chatId: String
    $userId: String
    $brandId: String
  ) {
    chatSubscription(
      chatId: $chatId
      userId: $userId
      brandId: $brandId
    ) {
      ... ChatMessageFragment
      chat {
        ... ChatFragment
        user {
          ... UserFragment
        }
        brand {
          ... BrandFragment
        }
        lastMessage {
          ... ChatMessageFragment
        }
      }
      createdBy {
        ... UserFragment
      }
    }
  }
`);
