import { Dispatch, SetStateAction, useCallback, useState } from 'react';

const useBooleanState: (initial: boolean | undefined) => [
  boolean,
  () => void,
  () => void,
  () => void,
  Dispatch<SetStateAction<boolean>>,
] = (initial = false) => {
  const [bool, setBool] = useState<boolean>(initial);
  const toggle = useCallback(() => setBool((prev) => !prev), []);
  const setTrue = useCallback(() => setBool(true), []);
  const setFalse = useCallback(() => setBool(false), []);
  return [bool, toggle, setTrue, setFalse, setBool];
};

export default useBooleanState;
