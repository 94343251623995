import { useQuery } from '@apollo/client';
import { PRE_UPLOAD_REQUIREMENTS } from '~graphql/campaign/query';
import { getFragmentData } from '~graphqlTypeScript';
import { PreUploadRequirementFragmentFragmentDoc } from '~graphqlTypeScript/graphql';

const usePreUploadRequirements = () => {
  const { data, loading } = useQuery(PRE_UPLOAD_REQUIREMENTS);
  const preUploadRequirementsFields = getFragmentData(
    PreUploadRequirementFragmentFragmentDoc,
    data?.generic.preUploadRequirements,
  ) || [];
  return {
    preUploadRequirementsData: data?.generic.preUploadRequirements,
    loading,
    preUploadRequirementsFields,
    campaignRequirements: preUploadRequirementsFields.filter((u) => u.campaignRequirement),
    contentRequirements: preUploadRequirementsFields.filter((u) => u.contentRequirement),
  };
};

export default usePreUploadRequirements;
