import { useSubscription } from '@apollo/client';
import { ChatFragment } from '~graphql/chat/fragment';
import { SUBSCRIBE_TO_NEW_CHAT } from '~graphql/chat/subscription';
import { getFragmentData } from '~graphqlTypeScript';
import { BrandFragmentFragmentDoc } from '~graphqlTypeScript/graphql';
import { updateChatMessageCache, updateChatsFromChatMessage } from './util';

type Props = {
  brandId?: string
  chatId?: string
};

const useSubscribeToChat = ({ brandId, chatId }: Props) => {
  useSubscription(SUBSCRIBE_TO_NEW_CHAT, {
    variables: {
      brandId,
      chatId,
    },
    skip: !brandId && !chatId,
    onData: ({ client, data: onData }) => {
      const chatSubscription = onData.data?.chatSubscription;
      if (!chatSubscription) return;
      const chat = getFragmentData(
        ChatFragment,
        chatSubscription.chat,
      );
      const brand = getFragmentData(
        BrandFragmentFragmentDoc,
        chatSubscription.chat.brand,
      );
      updateChatsFromChatMessage({
        message: chatSubscription,
        cache: client.cache,
        brandId: brand.id,
      });
      updateChatMessageCache({
        message: chatSubscription,
        cache: client.cache,
        chatId: chat.id,
      });
    },
  });
};

export default useSubscribeToChat;
