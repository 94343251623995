import { QueryHookOptions, useQuery } from '@apollo/client';
import { WorkspaceFragment } from '~graphql/user/fragment';
import { MY_BRANDS } from '~graphql/user/query';
import { getFragmentData } from '~graphqlTypeScript';
import { BrandFragmentFragmentDoc, MyBrandsQuery, MyBrandsQueryVariables } from '~graphqlTypeScript/graphql';

const useMyBrands = (
  queryOptions?: QueryHookOptions<MyBrandsQuery, MyBrandsQueryVariables>,
  workspaceId?: string,
) => {
  const { data, loading } = useQuery(MY_BRANDS, queryOptions);
  const brands = data?.brandResource.userBrands || [];
  const filteredBrands = brands.filter(
    (userBrand) => {
      const workspace = getFragmentData(
        WorkspaceFragment,
        userBrand?.brand.workspacebrand?.workspace,
      );
      return workspace?.id === workspaceId;
    },
  );
  const basicFieldBrands = getFragmentData(
    BrandFragmentFragmentDoc,
    brands.map((b) => b.brand),
  );
  return { brands, loading, filteredBrands, basicFieldBrands };
};

export default useMyBrands;
