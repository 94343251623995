import { useQuery } from '@apollo/client';
import { GET_USER } from '~graphql/user/query';
import { getFragmentData } from '~graphqlTypeScript';
import { UserFragmentFragmentDoc } from '~graphqlTypeScript/graphql';

const useGetUser = (userId?: string) => {
  const query = useQuery(GET_USER, {
    variables: {
      id: userId || '',
    },
    skip: !userId,
  });
  const user = getFragmentData(
    UserFragmentFragmentDoc,
    query.data?.userResource.get,
  );
  return { ...query, user };
};

export default useGetUser;
