import { Box, Card, CardContent, CardHeader, CardProps, LinearProgress, Skeleton, Stack, Typography, TypographyProps } from '@mui/material';
import React from 'react';

type Props = {
  loading?: boolean
  title: string
  mainResult?: string
  subtitle?: string
  rows?: { name: string, value: string }[]
  linearProgress?: { name: string, percentage: number }[]
  children?: React.ReactNode
  cardProps?: CardProps
  mainResultProps?: TypographyProps
  headerTypographyProps?: TypographyProps
};

const ReportResultCard = (props: Props) => {
  const {
    loading,
    title,
    mainResult,
    subtitle,
    rows,
    linearProgress,
    children,
    cardProps,
    mainResultProps,
    headerTypographyProps,
  } = props;
  return (
    <Card variant="outlined" sx={{ height: '100%' }} {...cardProps}>
      <CardHeader
        title={title}
        titleTypographyProps={{
          textAlign: 'center',
          variant: 'h5',
          color: 'text.secondary',
          ...headerTypographyProps,
        }}
      />
      <CardContent>
        {loading ? (
          <Box>
            <Skeleton
              variant="rounded"
              height={50}
              width={150}
              sx={{
                mx: 'auto',
              }}
            />
            <Skeleton
              variant="rounded"
              height={30}
              width={120}
              sx={{
                mx: 'auto',
                mt: 1,
              }}
            />
            <Skeleton
              variant="rounded"
              height={30}
              sx={{
                mt: 2,
              }}
            />
            <Skeleton
              variant="rounded"
              height={30}
              sx={{
                mt: 1,
              }}
            />
          </Box>
        ) : (
          <>
            {mainResult && (
              <Typography textAlign="center" variant="h2" color="primary.light" {...mainResultProps}>
                {mainResult}
              </Typography>
            )}
            {subtitle && (
              <Typography textAlign="center" variant="body2">
                {subtitle}
              </Typography>
            )}
            {children && (
              <Box mt={1}>
                {children}
              </Box>
            )}
            {rows && (
              <Stack mt={2} spacing={2}>
                {rows?.map((row) => (
                  <Stack direction="row" spacing={1} key={row.name} justifyContent="space-between">
                    <Typography>{row.name}</Typography>
                    <Typography variant="h5" color="text.secondary">{row.value}</Typography>
                  </Stack>
                ))}
              </Stack>
            )}
            {linearProgress && (
              <Stack mt={1} spacing={2}>
                {linearProgress?.sort((a, b) => b.percentage - a.percentage).map((progress) => (
                  <Box key={progress.name}>
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Typography variant="body2" noWrap>
                        {progress.name}
                      </Typography>
                      <Typography variant="h6">{(progress.percentage * 100).toFixed(0)}%</Typography>
                    </Box>
                    <Box mt={1}>
                      <LinearProgress
                        variant="determinate"
                        sx={{ width: '100%' }}
                        value={progress.percentage * 100}
                      />
                    </Box>
                  </Box>
                ))}
              </Stack>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ReportResultCard;
