import { graphql } from '~graphqlTypeScript';

export const UserFragment = graphql(`
  fragment UserFragment on UserType {
    id
    firstName
    lastName
    phoneNumber
    email
    identifier
    biography
    initialized
    fullName
    profilePicture {... FileFields}
  }
`);

export const SocialNetworkUserFragment = graphql(`
fragment SocialNetworkUserFragment on SocialNetworkUserType {
    id
    platformUserId
    username
    name
    profilePicture {
      ... FileFields
    }
    integrated
    followers
    follows
    mediaCount
    platform {
      ... PlatformFragment
    }
    engagement
  }
`);

export const InvitationCodeFragment = graphql(`
  fragment InvitationCodeFragment on InvitationCodeType {
    id
    code
    timesUsed
  }
`);

export const UserWorkspaceFragment = graphql(`
  fragment UserWorkspaceFragment on UserWorkspaceType {
    id
    isAdmin
    accountHolder
  }
`);

export const WorkspaceFragment = graphql(`
  fragment WorkspaceFragment on WorkspaceType {
    id
    name
  }
`);

export const WorkspaceBrandFragment = graphql(`
  fragment WorkspaceBrandFragment on WorkspaceBrandType {
    id
  }
`);

export const UserPortfolioFragment = graphql(`
  fragment UserPortfolioFragment on UserPortfolioType {
    id
    link
    name
  }
`);

export const UserPerformanceFragment = graphql(`
  fragment UserPerformanceFragment on UserPerformanceType {
    id
    avgReview
    totalReviews
  }
`);
