import { useQuery } from '@apollo/client';
import { GET_BRAND } from '~graphql/brand/query';
import { getFragmentData } from '~graphqlTypeScript';
import { BrandFragmentFragmentDoc, FileFieldsFragmentDoc } from '~graphqlTypeScript/graphql';

const useGetBrand = (brandId?: string) => {
  const { data, loading } = useQuery(
    GET_BRAND,
    {
      variables: {
        id: brandId || '',
      },
      skip: !brandId,
    },
  );
  if (!data?.brandResource.brand) {
    return {
      brand: undefined,
      loading,
      brandFields: undefined,
    };
  }
  const convertedBrand = getFragmentData(
    BrandFragmentFragmentDoc,
    data.brandResource.brand,
  );
  const brandFields = {
    ...convertedBrand,
    image: getFragmentData(
      FileFieldsFragmentDoc,
      convertedBrand.image,
    ),
  };
  return {
    brand: data?.brandResource.brand,
    loading,
    brandFields,
  };
};

export default useGetBrand;
