import { useCallback, useState } from 'react';

const useCounter = (top: number | undefined) => {
  const [count, setCount] = useState<number>(0);
  const next = useCallback(() => {
    setCount((prev) => {
      if (top) {
        return Math.min(top, prev + 1);
      }
      return prev + 1;
    });
  }, []);
  const previous = useCallback(() => {
    setCount((prev) => Math.max(0, prev - 1));
  }, []);
  return {
    count,
    next,
    previous,
    setCount,
  };
};
export default useCounter;
