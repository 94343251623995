import Button from '@mui/material/Button';
import Grid, { GridProps } from '@mui/material/Grid';
import { useCallback, useState } from 'react';

type ButtonProp = {
  id: string
  label: string
  value: any
};

type Props = {
  buttons: ButtonProp[]
  setButtonValue: (value: any) => void
  gridProps?: GridProps
};

const ButtonSelectedGroup = ({
  buttons,
  setButtonValue,
  gridProps,
}: Props) => {
  const [selectedButton, setSelectedButton] = useState<string>(buttons[0].id);
  const handleCallback = useCallback((button: ButtonProp) => () => {
    setButtonValue(button.value);
    setSelectedButton(button.id);
  }, []);
  return (
    <Grid container columnSpacing={1} {...gridProps}>
      {buttons.map((button) => (
        <Grid item key={button.id}>
          <Button
            variant={selectedButton === button.id ? 'contained' : 'outlined'}
            size="small"
            sx={{
              borderRadius: 20,
            }}
            onClick={handleCallback(button)}
          >
            {button.label}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default ButtonSelectedGroup;
