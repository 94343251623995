import { useQuery } from '@apollo/client';
import { GENDERS } from '~graphql/generic/query';
import { getFragmentData } from '~graphqlTypeScript';
import { GenderFragmentFragmentDoc } from '~graphqlTypeScript/graphql';

const useGenders = () => {
  const query = useQuery(GENDERS);
  const genders = getFragmentData(
    GenderFragmentFragmentDoc,
    query.data?.generic.genders,
  ) || [];
  return { genders, ...query };
};

export default useGenders;
