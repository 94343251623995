import { graphql } from '~graphqlTypeScript';

export const POST_TYPES = graphql(`
  query postTypes {
    generic {
      postTypes {
        ... PostTypeFragment
      }
    }
  }
`);
