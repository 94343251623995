import { graphql } from '~graphqlTypeScript';

export const WORK_ORDERS = graphql(`
  query workOrders (
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [ID]
    $id: ID
    $ambassador_Id_In: [ID]
    $ambassador_Id: ID
    $campaign_Brand_Id: ID
    $campaign_Brand_Id_In: [ID]
    $campaign_Id: ID
    $campaign_Id_In: [ID]
    $orderBy: String
    $search: String
    $status: MontuWorkOrderStatusChoices
    $status_In: [MontuWorkOrderStatusChoices]
  ) {
    workOrder {
      list (
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        ambassador_Id_In: $ambassador_Id_In
        ambassador_Id: $ambassador_Id
        campaign_Brand_Id: $campaign_Brand_Id
        campaign_Brand_Id_In: $campaign_Brand_Id_In
        campaign_Id: $campaign_Id
        campaign_Id_In: $campaign_Id_In
        orderBy: $orderBy
        search: $search
        status: $status
        status_In: $status_In
      ) {
        pageInfo {
          ... PageInfoFragment
        }
        edges {
          cursor
          node {
            ... WorkOrderFragment
            ambassador {
              ... UserFragment
            }
            campaign {
              ... CampaignFragment
            }
          }
        }
      }
    }
  }
`);

export const WORK_ORDER_DETAIL = graphql(`
  query workOrderDetail(
    $workOrderId: ID!
  ) {
    workOrder {
      get(
        id: $workOrderId
      ) {
        ... WorkOrderFragment
        ambassador {
          ... UserFragment
          socialnetworkuserSet {
            ... SocialNetworkUserFragment
          }
          gender {
            ... GenderFragment
          }
          useraddressSet {
            id
            address {
              ... AddressFields
            }
          }
        }
        campaign {
          ... CampaignFragment
          brand { ...BrandFragment }
        }
        workordertaxlineSet {...WorkOrderTaxLineFragment}
        postulation {
          id
          address {
            id
            address {...AddressFields}
          }
        }
        workorderdetailSet {
          ... WorkOrderDetailFragment
          contentapprovalSet {
            ... ContentApprovalFragment
          }
          posts {
            ... PostFragment
            postType {... PostTypeFragment}
          }
          postType {
            ... PostTypeFragment
          }
          contentRequirement {
            ... ContentRequirementFragment
            references {
              ... CampaignReferenceFragment
            }
            preUploadRequirements {
              ... PreUploadRequirementFragment
            }
          }
        }
      }
    }
  }
`);

export const WORK_ORDER_DASHBOARD = graphql(`
  query workOrderDashboard (
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [ID]
    $id: ID
    $ambassador_Id_In: [ID]
    $ambassador_Id: ID
    $campaign_Brand_Id: ID
    $campaign_Brand_Id_In: [ID]
    $campaign_Id: ID
    $campaign_Id_In: [ID]
    $orderBy: String
    $search: String
  ) {
    workOrder {
      acceptedWorkOrderList: list (
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        ambassador_Id_In: $ambassador_Id_In
        ambassador_Id: $ambassador_Id
        campaign_Brand_Id: $campaign_Brand_Id
        campaign_Brand_Id_In: $campaign_Brand_Id_In
        campaign_Id: $campaign_Id
        campaign_Id_In: $campaign_Id_In
        orderBy: $orderBy
        search: $search
        status: ACCEPTED
      ) {
        pageInfo {
          ... PageInfoFragment
        }
        edges {
          cursor
          node {
            ... WorkOrderFragment
            ambassador {
              ... UserFragment
            }
          }
        }
      }
      declinedWorkOrderList: list (
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        ambassador_Id_In: $ambassador_Id_In
        ambassador_Id: $ambassador_Id
        campaign_Brand_Id: $campaign_Brand_Id
        campaign_Brand_Id_In: $campaign_Brand_Id_In
        campaign_Id: $campaign_Id
        campaign_Id_In: $campaign_Id_In
        orderBy: $orderBy
        search: $search
        status: DECLINED
      ) {
        pageInfo {
          ... PageInfoFragment
        }
        edges {
          cursor
          node {
            ... WorkOrderFragment
            ambassador {
              ... UserFragment
            }
          }
        }
      }
      finishedWorkOrderList: list (
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        ambassador_Id_In: $ambassador_Id_In
        ambassador_Id: $ambassador_Id
        campaign_Brand_Id: $campaign_Brand_Id
        campaign_Brand_Id_In: $campaign_Brand_Id_In
        campaign_Id: $campaign_Id
        campaign_Id_In: $campaign_Id_In
        orderBy: $orderBy
        search: $search
        status: FINISHED
      ) {
        pageInfo {
          ... PageInfoFragment
        }
        edges {
          cursor
          node {
            ... WorkOrderFragment
            ambassador {
              ... UserFragment
            }
          }
        }
      }
      pendingWorkOrderList: list (
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        ambassador_Id_In: $ambassador_Id_In
        ambassador_Id: $ambassador_Id
        campaign_Brand_Id: $campaign_Brand_Id
        campaign_Brand_Id_In: $campaign_Brand_Id_In
        campaign_Id: $campaign_Id
        campaign_Id_In: $campaign_Id_In
        orderBy: $orderBy
        search: $search
        status: PENDING
      ) {
        pageInfo {
          ... PageInfoFragment
        }
        edges {
          cursor
          node {
            ... WorkOrderFragment
            ambassador {
              ... UserFragment
            }
          }
        }
      }
      pendingPaymentWorkOrderList: list (
        offset: $offset
        before: $before
        after: $after
        first: $first
        last: $last
        id_In: $id_In
        id: $id
        ambassador_Id_In: $ambassador_Id_In
        ambassador_Id: $ambassador_Id
        campaign_Brand_Id: $campaign_Brand_Id
        campaign_Brand_Id_In: $campaign_Brand_Id_In
        campaign_Id: $campaign_Id
        campaign_Id_In: $campaign_Id_In
        orderBy: $orderBy
        search: $search
        status: PENDING_PAYMENT
      ) {
        pageInfo {
          ... PageInfoFragment
        }
        edges {
          cursor
          node {
            ... WorkOrderFragment
            ambassador {
              ... UserFragment
            }
          }
        }
      }
    }
  }
`);

export const GET_WORK_ORDER_VALUES_PURCHASE_ORDER = graphql(`
  query getWorkOrderValuesWithPurchaseOrder($workOrderId: ID!) {
    workOrder {
      valuesWithPurchaseOrder(workOrderId: $workOrderId) {
        cashPayment { ... MoneyFields}
        retention { ... MoneyFields}
        paymentBeforeTaxes { ... MoneyFields}
        serviceFee { ... MoneyFields}
        totalTaxes { ... MoneyFields}
        totalPayment { ... MoneyFields}
      }
    }
  }
`);
