import { Badge, Box, Drawer, Link, List, ListItem, ListItemText, Theme, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import MontuLogo from '~assets/montu-logo-dark-background.png';
import MontuLogoLightBg from '~assets/montu-logo-light-background.png';
import { useIsSidebarOpen } from '~hooks';
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from './constants';

export type RouteProp = {
  to: string
  text: string
  icon: React.ReactNode
  index?: boolean
  badgeContent?: React.ReactNode
  hide?: boolean
};

export type Props = {
  routes: RouteProp[]
  titleElement?: React.ReactNode
};

const openedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(SIDEBAR_OPEN_WIDTH),
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(SIDEBAR_CLOSED_WIDTH)} + 1px)`,
});

type MontuNavLinkProps = NavLinkProps & {
  disableLink?: boolean
};

const SidebarNavLink = React.forwardRef<any, any>((props: MontuNavLinkProps, ref) => {
  const theme = useTheme();
  const { disableLink, ...restProps } = props;
  const component = (
    <NavLink
      ref={ref}
      style={({ isActive }) => ({
        color: isActive ? theme.palette.primary.contrastText : theme.palette.grey[700],
        backgroundColor: isActive ? theme.palette.primary.main : 'inherit',
        borderRadius: theme.shape.borderRadius * 2,
        width: 100,
        marginRight: 'auto',
        marginLeft: 'auto',
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.standard,
        }),
      })}
      onClick={(e) => {
        if (disableLink) {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      {...restProps}
    />
  );
  if (disableLink) {
    return (
      <Tooltip title="Contrata Montu Plus para habilitar esta opción">
        {component}
      </Tooltip>
    );
  }
  return component;
});

const Sidebar = (props: Props) => {
  const { routes, titleElement } = props;
  const { open, toggleOpen, drawerBig } = useIsSidebarOpen();
  const drawerItem = (
    <List
      sx={{
        px: 2,
        width: '100%',
        pt: {
          xs: 2,
          md: 2,
        },
        height: '100%',
      }}
    >
      <Link href="/app" display="flex" alignItems="center">
        <Box
          component="img"
          src={MontuLogo}
          sx={{
            width: '100%',
            display: {
              xs: 'none',
              md: 'inherit',
            },
          }}
        />
        <Box
          component="img"
          src={MontuLogoLightBg}
          sx={{
            width: '100%',
            display: {
              xs: 'inherit',
              md: 'none',
            },
          }}
        />
      </Link>
      {titleElement ? (
        <Box mt={2}>
          {titleElement}
        </Box>
      ) : <></>}
      {routes.map((route) => (
        <ListItem
          key={route.to}
          component={SidebarNavLink}
          to={route.to}
          sx={{
            width: '100%',
            mt: {
              xs: 1,
              md: 1,
            },
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
          }}
          end={route.index === true}
          disableLink={route.hide}
        >
          <Badge badgeContent={route.badgeContent} color="primary">
            {route.icon}
          </Badge>
          <ListItemText
            primary={route.text}
            sx={{
              display: {
                md: drawerBig ? 'inherit' : 'none',
                xs: 'inherit',
              },
            }}
            primaryTypographyProps={{
              variant: 'h6',
              textAlign: 'center',
            }}
          />
        </ListItem>
      ))}
    </List>
  );
  return (
    <>
      <Drawer
        variant="permanent"
        elevation={0}
        sx={{
          display: {
            xs: 'none',
            md: 'inherit',
          },
        }}
        PaperProps={{
          sx: (theme) => ({
            p: 0,
            ...(drawerBig ? {
              ...openedMixin(theme),
            } : {
              ...closedMixin(theme),
            }),
            bgcolor: 'transparent',
          }),
        }}
      >
        {drawerItem}
      </Drawer>
      <Drawer
        variant="temporary"
        elevation={0}
        sx={{
          display: {
            xs: 'inherit',
            md: 'none',
          },
        }}
        open={open}
        onClose={toggleOpen}
        PaperProps={{
          sx: (theme) => ({
            p: 0,
            bgcolor: theme.palette.background.default,
            ...openedMixin(theme),
          }),
        }}
      >
        {drawerItem}
      </Drawer>
    </>
  );
};

export default Sidebar;
