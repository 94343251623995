import { Divider, List, ListItem, ListItemProps as MuiListItemProps, Skeleton } from '@mui/material';
import React from 'react';

type Props<T> = {
  loading: boolean
  items: T[] | undefined | null
  renderItem: (item: T) => React.ReactNode
  showDivider?: boolean
  keyExtractor?: (item: T) => number | string
  ListItemProps?: MuiListItemProps | undefined
  renderSecondaryAction?: (item: T) => React.ReactNode | undefined
};

const LoadingList = <T extends unknown>(props: Props<T>) => {
  const {
    loading,
    renderItem,
    items,
    showDivider = true,
    keyExtractor = (item: any) => item.id,
    ListItemProps,
    renderSecondaryAction,
  } = props;
  return (
    <List>
      {loading ? (
        [1, 2, 3, 4].map((value) => (
          <ListItem key={value}>
            <Skeleton variant="text" width={150} height={40} />
          </ListItem>
        ))) : (
        items?.map((item, index) => (
          <React.Fragment key={keyExtractor(item)}>
            <ListItem
              {...(renderSecondaryAction && { secondaryAction: renderSecondaryAction(item) })}
              {...ListItemProps}
            >
              {renderItem(item)}
            </ListItem>
            {(showDivider && index < items.length - 1) ? <Divider /> : <></>}
          </React.Fragment>
        ))
      )}
    </List>
  );
};

export default LoadingList;
