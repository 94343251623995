import { Grid, Typography } from '@mui/material';
import { SubscriptionAbilities } from '~graphqlTypeScript/graphql';
import AbilityTypography from './AbilityTypography';

type AbilityToSee = {
  brandId: string
  ability: SubscriptionAbilities
};

export type DisplayValueRowProp<T> = {
  label: string,
  renderValue: ({ item }: { item: T }) => React.ReactNode | string
  abilityToSee?: AbilityToSee
};

export type Props<T> = {
  item: T
  rows: DisplayValueRowProp<T>[]
};

const DisplayValueRows = <T extends unknown>(props: Props<T>) => {
  const { item, rows } = props;
  const getComponent = (value: string, abilityToSee?: AbilityToSee) => {
    if (abilityToSee) {
      return (
        <AbilityTypography variant="body1" color="text.secondary" ability={abilityToSee.ability} brandId={abilityToSee.brandId}>
          {value}
        </AbilityTypography>
      );
    }
    return (
      <Typography variant="body1" color="text.secondary">
        {value}
      </Typography>
    );
  };
  return (
    <Grid container spacing={2}>
      {rows.map(
        ({ label, renderValue, abilityToSee }) => {
          const component = renderValue({ item });
          return (
            <Grid item container justifyContent="space-between" key={label} flexWrap="nowrap">
              <Typography>{label}</Typography>
              {typeof component === 'string' ? (
                getComponent(component, abilityToSee)
              ) : (
                component
              )}
            </Grid>
          );
        },
      )}
    </Grid>
  );
};

export default DisplayValueRows;
