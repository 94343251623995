import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { LoadingButton } from '@mui/lab';
import { ButtonProps, Grid, GridProps } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

export type BackNextBoxProps = {
  next: {
    label: string
    onClick?: () => void
    loading?: boolean
    buttonProps?: ButtonProps
  }
  back: {
    label: string
    onClick?: () => void
    loading?: boolean
    buttonProps?: ButtonProps
  }
  isNextDisabled?: boolean
  disabledTooltip?: string
  gridProps?: Omit<GridProps, 'children'>
};

const BackNextBox = ({
  next,
  back,
  gridProps,
  isNextDisabled = false,
  disabledTooltip = '',
}: BackNextBoxProps) => (
  <Grid
    container
    justifyContent="space-between"
    {...gridProps}
  >
    <LoadingButton
      variant="outlined"
      size="large"
      sx={{ minWidth: 150, maxWidth: 200 }}
      onClick={back.onClick}
      loading={back.loading}
      {...back.buttonProps}
    >
      {back.label}
    </LoadingButton>
    <Tooltip title={isNextDisabled ? disabledTooltip : ''}>
      <Box component="span">
        <LoadingButton
          variant="contained"
          size="large"
          sx={{ minWidth: 150, maxWidth: 200 }}
          onClick={next.onClick}
          disabled={isNextDisabled}
          endIcon={<ArrowRightAltIcon />}
          loading={next.loading}
          {...next.buttonProps}
        >
          {next.label}
        </LoadingButton>
      </Box>
    </Tooltip>
  </Grid>
);

export default BackNextBox;
