import { useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
import { isDrawerBig, isSidebarOpen } from '~apollo';

const useIsSidebarOpen = () => {
  const open = useReactiveVar(isSidebarOpen);
  const drawerBig = useReactiveVar(isDrawerBig);
  const toggleOpen = useCallback(() => {
    isSidebarOpen(!isSidebarOpen());
  }, []);
  const toggleDrawerBig = useCallback(() => {
    isDrawerBig(!isDrawerBig());
  }, []);
  return { open, toggleOpen, drawerBig, toggleDrawerBig };
};

export default useIsSidebarOpen;
