import { useQuery } from '@apollo/client';
import { STATES } from '~graphql/country/query';
import { getFragmentData } from '~graphqlTypeScript';
import { StateFragmentFragmentDoc } from '~graphqlTypeScript/graphql';

const useStatesFromCountry = (
  countryId :string = '',
) => {
  const { data, loading } = useQuery(
    STATES,
    {
      variables: { countryId },
      skip: !countryId,
    },
  );
  const states = getFragmentData(
    StateFragmentFragmentDoc,
    data?.countryCity.states,
  ) || [];
  return { states, loading, countryId };
};

export default useStatesFromCountry;
