import { Button, ButtonProps } from '@mui/material';

export type LoginButtonProps = {
  backgroundColor: string
  textColor: string
} & ButtonProps;
const LoginButton = (
  {
    backgroundColor,
    textColor,
    sx,
    ...props
  }: LoginButtonProps,
) => (
  <Button
    disableRipple
    variant="contained"
    color="secondary"
    sx={{
      height: 46,
      width: '100%',
      bgcolor: backgroundColor,
      color: textColor,
      ':hover': {
        bgcolor: backgroundColor,
        color: textColor,
      },
      ...sx,
    }}
    {...props}
  />
);

export default LoginButton;
