import { QueryHookOptions, useQuery } from '@apollo/client';
import { PRODUCTS } from '~graphql/product/query';
import { getFragmentData } from '~graphqlTypeScript';
import { ProductFragmentFragmentDoc, ProductsQuery, ProductsQueryVariables } from '~graphqlTypeScript/graphql';

const useMyProducts = (options: QueryHookOptions<ProductsQuery, ProductsQueryVariables>) => {
  const { data, ...props } = useQuery(PRODUCTS, options);
  const products = getFragmentData(
    ProductFragmentFragmentDoc,
    data?.product.list.edges.map((n) => n.node),
  ) || [];
  const pageInfo = data?.product.list.pageInfo;
  return { products, pageInfo, ...props };
};

export default useMyProducts;
