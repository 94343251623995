import { useQuery } from '@apollo/client';
import { graphqlDate } from '~formattingValues';
import { ACCOUNT_METRIC_TOTAL_VALUE } from '~graphql/accountMetric/query';
import { getFragmentData } from '~graphqlTypeScript';
import { AccountMetricFragmentFragmentDoc, AccountMetricQueryTotalValuesArgs, AccountMetricTotalValueFragmentFragmentDoc } from '~graphqlTypeScript/graphql';

type Props = {
  variables: AccountMetricQueryTotalValuesArgs
};
const useTotalValueMetric = (props: Props) => {
  const { variables } = props;
  const { since, until } = variables;
  const { data, loading } = useQuery(ACCOUNT_METRIC_TOTAL_VALUE, {
    variables: {
      ...variables,
      since: since && graphqlDate(since),
      until: until && graphqlDate(until),
    },
  });
  const insights = data?.accountMetric.totalValues.map((insight) => (
    {
      ...getFragmentData(AccountMetricTotalValueFragmentFragmentDoc, insight),
      accountMetric: getFragmentData(AccountMetricFragmentFragmentDoc, insight.accountMetric),
    }
  )) || [];
  return { insights, loading };
};

export default useTotalValueMetric;
