import { graphql } from '~graphqlTypeScript';

export const GET_BRAND = graphql(`
  query getBrand(
    $id: ID!
  ) {
    brandResource {
      brand(
        id: $id
      ) {
        ... BrandFragment
        brandsocialnetworkuserSet {
          ... BrandSocialNetworkUserFragment
          socialNetworkUser {
            ... SocialNetworkUserFragment
          }
        }
      }
    }
  }
`);

export const DASHBOARD_BRAND = graphql(`
  query dashboardBrand(
    $brandId: String!
  ) {
    brandResource {
      dashboard(brandId: $brandId) {
        activeCampaigns
        activeWorkOrders
        unreadMessages
        newPostulations
      }
    }
  }
`);

export const COLLABORATIONS_DASHBOARD_BRAND = graphql(`
  query collaborationsDashboardBrand(
    $brandId: String!
  ) {
    brandResource {
      collaborationsDashboard(brandId: $brandId) {
        createdCampaigns
        creatorsSelected
        priceProducts {
          ...MoneyFields
        }
        payments {
          ...MoneyFields
        }
      }
    }
  }
`);
